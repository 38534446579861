import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

export default {
    methods: {
        getComponentName(type, edit = false) {
          if (edit) return "a-input-table-" + type;
          return "a-view-table-" + type;
        },
        componentExist(type, edit = false) {
          const componentName = upperFirst(
            camelCase(
              this.getComponentName(type, edit)
                .replace(/^\.\//, "")
                .replace(/\.\w+$/, "")
            )
          );
          return this.$root.$options.components[componentName] ? true : false;
        },
      },
}
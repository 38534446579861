export default {
    appName: "santex",
    baseUrl: "https://api.santehkomfort.ru/api",
    websocetUrl: "wss://api.santehkomfort.ru/data",
    imageDownload: "https://api.santehkomfort.ru/api/image/download/",
    mainMenu: [
        {
            auth: 100,
            name: "Каталог",
            icon: "fas fa-table",
            route: {
                name: "catalog",
            },
        },
        {
            auth: 100,
            name: "Контент",
            icon: "fas fa-envelope-open-text",
            route: {
                name: "content",
            },
        },
        {
            auth: 100,
            name: "Интерьеры",
            icon: "fas fa-images",
            route: {
                name: "interior",
            },
        },
        {
            auth: 100,
            name: "Продвижения",
            icon: "fas fa-list",
            route: {
                name: "promote",
            },
        },
        {
            auth: 200,
            name: "Категории",
            icon: "fas fa-shapes",
            route: {
                name: "categories",
            },
        },
        {
            auth: 200,
            name: "Фильтры",
            icon: "fas fa-filter",
            route: {
                name: "filters",
            },
        },
        {
            auth: 200,
            name: "Бренды",
            icon: "fas fa-warehouse",
            route: {
                name: "brends",
            },
        },
        {
            auth: 1000,
            name: "Пользователи",
            icon: "fas fa-users",
            route: {
                name: "users",
            },
        },
    ],
    enum: {
        FilterStatuses: [
            {
                value: 0,
                text: "Не активый",
            },
            {
                value: 1,
                text: "Активный",
            },
            {
                value: 2,
                text: "Только в карточке",
            },
        ]
    }
}
export default {
  methods: {
    async onInput(value) {
      try {
        if (this.model.is_filter) {
          let data = {
            catalog_id: this.values.id,
            filter_id: this.model.filter_id,
            value: value
          }
          await this.$axios.post("/manager/catalog_filter", data);
          this.$root.$emit("show-info", {
            text: "Данные записаны",
          });
        } else {
          let data = {
            id: this.values.id,
          }
          data[this.model.name] = value;
          await this.$axios.post("/manager/catalog", data);
          this.$root.$emit("show-info", {
            text: "Данные записаны",
          });
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          text: "Ошибка записи",
        });
        console.error(error);
      }
      this.$emit("input", value);
      // console.log(value, this.values, this.model);
    }
  }
}
<template>
  <div class="my-6">
    <v-row v-for="(el, i) in model" :key="i">
      <template v-if="el.row">
        <v-col  class="py-0" v-for="(e, q) in el.row" :key="q">
          <component v-if="e.type" :is="'a-input-' + e.type" :model="e" :values="data" :value="data[e.name]"
          @input="onInput($event, e)" :error="e.type == 'ref' ? errors[e.name + '_id'] : errors[e.name]"></component>
        </v-col>
      </template>
      <template v-else>
        <v-col class="py-0">
        <component v-if="el.type" :is="'a-input-' + el.type" :model="el" :values="data" :value="data[el.name]"
          @input="onInput($event, el)" :error="el.type == 'ref' ? errors[el.name + '_id'] : errors[el.name]"></component>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    model: Array,
    errors: Object
  },
  computed: {
    data: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    async onInput(e, el) {
      if (typeof e === "object" && e !== null) {
        if (Array.isArray(e)) {
          this.data[el.name] = e;
        } else {
          Object.assign(this.data, e);
        }
      } else {
        this.data[el.name] = e;
      }
      this.$emit("validate", el.name);
    },
  },
};
</script>
<template>
  <v-autocomplete
    outlined
    dense
    :disabled="disabled"
    :items="data"
    :value="value"
    :label="model.title"
    :error-messages="error"
    item-text="text"
    item-value="value"
    @input="onInput($event)"
  ></v-autocomplete>
</template>

<script>
export default {
  // mixins: [modelOptions],
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
  },
  methods: {
    onInput(e) {
      let res = {};
      res[this.model.name + "_name"] = this.data.find((x) => x.value == e).text;
      res[this.model.name] = e;
      this.$emit("input", res);
    },
    async fetchData() {
      this.loading = true;
      let response = await this.$axios.get(this.model.api, {
                params: { sort: this.model.sort?? "", filters: this.model.filters?? "", pager: this.model.pager?? "" },
            });
      this.loading = false;
      if (this.model.init) {
        this.data = this.model.init;
      } else {
        this.data = [];
      }
      for (const el of response.data.data) {
        this.data.push(el);
      }
    },
  },
};
</script>
<template>
  <td class="b-table-col" :class="[sortable ? 'sortable' : '']" :style="{ width: width + 'px' }"
    @click="onClick($event)">
    <div class="d-flex justify-space-between">
      <slot></slot>
      <template v-if="sortable">
        <div v-if="sort.key != field">
          <i class="fas fa-sort"></i>
        </div>
        <div v-else class="arrow">
          <i class="fas" :class="sort.order == 'DESC' ? 'fa-sort-up' : 'fa-sort-down'"></i>
        </div>
      </template>
    </div>
  </td>
</template>

<script>
export default {
  props: {
    sort: {
      type: Object,
      default: () => { }
    },
    sortable: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 100
    },
    field: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClick(event) {
      if (this.sortable) this.$emit('sort', this.field);
    }
  }
};
</script>
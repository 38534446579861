import Validate from './libs/validate';
import Validators from './libs/validators';
import FillForm from './libs/fillForm';
import SubmitForm from './libs/submitForm';
import Components from './libs/components';
import GetIndex from './libs/getIndex';
import GetForm from './libs/getForm';
import StaticFullHeight from './libs/staticFullHeight';
import RemoveEl from './libs/removeEl';
import ModelOptions from './libs/modelOptions';

export const validate = Validate;
export const validators = Validators;
export const fillForm = FillForm;
export const submitForm = SubmitForm;
export const components = Components;
export const getIndex = GetIndex;
export const getForm = GetForm;
export const staticFullHeight = StaticFullHeight;
export const removeEl = RemoveEl;
export const modelOptions = ModelOptions;
const routes = [
    {
        path: '/index',
        name: 'index',
        meta: { auth: 100, title: "Главная" },
        component: () => {
            return import('@/views/index.vue')
        }
    },
    {
        path: '/catalog',
        name: 'catalog',
        meta: { auth: 100, title: "Каталог" },
        component: () => {
            return import('@/views/catalog.vue')
        }
    },
    {
        path: '/brends',
        name: 'brends',
        meta: { auth: 100, title: "Бренды" },
        component: () => {
            return import('@/views/brends.vue')
        }
    },
    {
        path: '/content',
        name: 'content',
        meta: { auth: 100, title: "Контент" },
        component: () => {
            return import('@/views/content.vue')
        }
    },
    {
        path: '/interior',
        name: 'interior',
        meta: { auth: 100, title: "Интеръеры" },
        component: () => {
            return import('@/views/interior.vue')
        }
    },
    {
        path: '/promote',
        name: 'promote',
        meta: { auth: 100, title: "Страницы продвижения" },
        component: () => {
            return import('@/views/promote.vue')
        }
    },
    {
        path: '/categories',
        name: 'categories',
        meta: { auth: 100, title: "Категории" },
        component: () => {
            return import('@/views/categories.vue')
        }
    },
    {
        path: '/filters',
        name: 'filters',
        meta: { auth: 200, title: "Фильтры" },
        component: () => {
            return import('@/views/filters.vue')
        }
    },
    {
        path: '/users',
        name: 'users',
        meta: { auth: 1000, title: "Пользовтели" },
        component: () => {
            return import('@/views/users.vue')
        }
    },
];

export default {
    routes: routes
}
<template>
  <select
    class="f-search"
    :value="value"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
  >
    <option value="">Все</option>
    <option v-for="(el, i) in items" :key="i" :value="el.value">
      {{ el.text }}
    </option>
  </select>
</template>

<script>
import modelOptions from "./../../../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    model: Object,
  },  
  data() {
    return {
      items: []
    }
  },
  async created() {
    this.items = await this.getOptions(this.model);
  }
};
</script>
<style lang="scss">
@import "styles.scss";
</style>
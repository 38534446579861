<template>
  <select
    class="f-search"
    :value="value"
    @change="onInput($event.target.value)"
  >
    <option v-for="(el, i) in items" :key="i" :value="el.value">
      {{ el.text }}
    </option>
  </select>
</template>

<script>
import saveCatalog from './saveCatalog';
import modelOptions from "./../../../libs/modelOptions";

export default {
  mixins: [saveCatalog, modelOptions],
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      items: []
    }
  },
  async created() {
    this.items = await this.getOptions(this.model);
  }
};
</script>
<template>
  <input
    class="f-search"
    :value="v"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    value: [Number, String, Object],
  },
  computed: {
    v() {
      if (typeof this.value === "string" || typeof this.value === "number") {
        return this.value;
      }
      if (typeof this.value === "object" && this.value.value != undefined) {
        let r = this.value.value.match(/^%(.*)%$/);
        if (r) {
          return r[1];
        } else {
          return this.value.value;
        }
      }
      return "";
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";
</style>
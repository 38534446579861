<template>
  <div class="f-table-col" :style="{ width: width + 'px' }">
    <template v-if="model.searchable !== false">
      <a-input-table-search-string v-if="
        [
          'id',
          'string',
          'ref-table',
          'login',
          'number',
          'phones',
          'text',
          'sparams',
        ].includes(filter.type)
      " :value="values[filter.name]" @change="onInputElement" />
      <a-input-table-search-select v-else-if="['select'].includes(filter.type)" :value="values[filter.name]"
        :model="filter" @change="onInputElement" />
      <a-input-table-search-selectapi v-else-if="filter.type == 'selectapi'" :value="values[filter.name]"
        :model="filter" @change="onInputElement" />
      <a-input-table-search-datetime v-else-if="['date', 'datetime'].includes(filter.type)" :value="values[filter.name]"
        @input="onInputElement" />
    </template>
    <slot></slot>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  props: {
    model: Object,
    values: Object,
    width: [Number, String],
  },
  computed: {
    value() {
      return this.values[this.model.name];
    },
    filter() {
      if (this.model.filter) return this.model.filter;
      return this.model;
    }
  },
  methods: {
    // onInputElement: lodash.debounce(function (event) {
    //   this.values[this.model.name] = event;
    //   this.$emit("input", this.values);
    // }, 500),
    onInputElement(event) {
      let field = this.filter.name;
      if (["string", "login", "phones", "text"].includes(this.filter.type)) {
        let r = "";
        if (event) {
          r = {
            condition: "LIKE",
            value: "%" + event + "%",
          };
        }
        this.values[field] = r;
      } else if (["date", "datetime"].includes(this.filter.type)) {
        let d = event.split(" - ");
        let r = "";
        if (d[0] && d[1]) {
          r = [
            {
              condition: ">=",
              value: d[0] + " 00:00:00",
            },
            {
              condition: "<=",
              value: d[1] + " 23:59:59",
            },
          ];
        }
        this.values[field] = r;
      } else {
        this.values[field] = event;
      }
      this.$emit("input", this.values);
    },
    getOptions(field) {
      if (field.options) {
        return field.options;
      } else if (field.data && field.data.select && field.data.select.storage) {
        return this.options[field.data.select.storage];
      }
    },
  },
};
</script>
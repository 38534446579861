<template>
  <v-select outlined dense :items="items" item-text="text" item-value="value" :value="value" :label="$t(model.title)"
    :error-messages="error" :disabled="disabled" :hide-details="hideDetails" @input="$emit('input', $event)"
    :multiple="model.multiple"></v-select>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    model: Object,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      items: []
    }
  },
  async created() {
    this.items = await this.getOptions(this.model);
  }
};
</script>
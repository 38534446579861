<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-left">
                        Параметр
                    </th>
                    <th class="text-left">
                        Значение
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in options" :key="i">
                    <td>{{ item }}</td>
                    <td>
                        <v-text-field :value="getItem(i)" @input="setItem(i, $event)" />
                    </td>
                    <td style="width: 1%">
                        <v-btn class="mx-2" fab dark small color="error" @click.prevent="remove(i)">
                            <v-icon dark> mdi-close </v-icon>
                        </v-btn>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <!-- <td>
                        <v-btn class="mx-2" fab dark small color="primary" @click.prevent="addDialogShow = true">
                            <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                    </td> -->
                </tr>
            </tbody>
        </v-simple-table>
        <dialog-window v-model="addDialogShow">
            <h3>Добавить новый элемент</h3>
            <v-text-field v-model="newElement.name" :model="{title: 'Параметр'}"/>
            <v-text-field v-model="newElement.key" :model="{title: 'Поле'}"/>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn @click="add()" color="primary">Добавить</v-btn>
                <v-btn @click="addDialogShow = false">Отмена</v-btn>
                <v-spacer></v-spacer>
            </template>
        </dialog-window>
    </div>
</template>

<script>
export default {
    props: {
        value: Object
    },
    data() {
        return {
            addDialogShow: false,
            newElement: {
                name: "",
                key: "",
            },
            options: {
                url: 'Ссылка для перехода',
                video: "Ссылка на видео",
                type_view: "Тип отображения",
                phone: "Номер телефона",
                email: "Адрес почты"
            }
        }
    },
    watch: {
        addDialogShow(v) {
            if (v) {
                this.newElement.name = "";
                this.newElement.key = "";
            }
        }
    },
    methods: {
        getItem(i) {
            if (this.value && this.value[i]) {
                return this.value[i];
            } else {
                return "";
            }
        },
        setItem(i, value) {
            let k = {};
            if (typeof this.value == "object") {
                Object.assign(k, this.value);
            }
            k[i] = value;
            this.$emit('input', k);
        },
        add() {
            this.$set(this.options, this.newElement.key, this.newElement.name);
            this.addDialogShow = false;
        },
        remove(i) {
            this.setItem(i, null);
        }
    }
}
</script>
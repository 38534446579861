<template>
  <v-switch outlined class="ma-2" :value="value" @change="$emit('input', $event)" :label="model.title"
    :error-messages="error" :disabled="disabled"></v-switch>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
};
</script>
export default {
    data() {
        return {
            loading: false,
            data: [],
            sort: {
                key: "id",
                order: "DESC",
            },
            pager: {
                page: 0,
                count: 0,
                limit: 50,
            },
            filters: {},
        };
    },
    methods: {
        async updateData() {
            await this.fetchData();
        },
        async getRequest(params) {
            let data;
            try {
                const response = await this.$axios.get(this.api, {
                    params: params
                });
                data = response.data;
            } catch (error) {
                console.error(error);
            }
            return data;
        },
        async fetchData(append = false) {
            let params = { sort: this.sort, filters: this.filters, pager: this.pager };
            if (this.filtersList) params.filtersList = JSON.stringify(this.filtersList);
            this.loading = true;
            const data = await this.getRequest(params);
            this.loading = false;
            if (data) {
                this.pager = data.pager;
                if (append) {
                    this.data = this.data.concat(data.data);
                } else {
                    this.data = data.data;
                }
                this.$emit('dataUpdated', data);
                await this.afterFetchData(data);
            }
        },
        async afterFetchData(data) {

        }
    },
};
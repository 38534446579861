export default {
    methods: {
        async getOptions(model) {
            console.log(model);
            if (model && model.options) {
                if (Array.isArray(model.options)) return model.options;
                else if (model.options.api) {
                    try {
                        let params = {};
                        if (model.options.params) {
                            if (model.options.params.sort) params.sort = JSON.stringify(model.options.params.sort);
                            if (model.options.params.filters) params.filters = JSON.stringify(model.options.params.filters);
                            if (model.options.params.pager) params.pager = JSON.stringify(model.options.params.pager);
                        }
                        let response = await this.$axios.get(model.options.api, { params: params });
                        return response.data.data;
                    } catch (error) {
                        this.$root.$emit("show-info", { text: error.toString(), color: 'red' });
                    }
                }
                return this.$root.config.meta?.enum[model.options];
            }
        },
        getElementByValue(value, model) {
            let options = model.options;
            if (options) {
                return options.find((x) => x.value == value)
                    ? options.find((x) => x.value == value)
                    : {};
            }
        },
        getTextByValue(value, model) {
            let text = this.getElementByValue(value, model)
            return text.text ? text.text : this.$t('none')
        }
    },
}
<template>
  <input class="f-search" :value="value" @change="onInput($event.target.value)" />
</template>

<script>
import saveCatalog from './saveCatalog';

export default {
  mixins: [saveCatalog],
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  methods: {
  }
};
</script>
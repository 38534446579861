<template>
  <div class="b-table-wrapper">
    <slot name="loader">
      <a-loader v-if="loading" />
    </slot>
    <div ref="table" style="position: absolute; top: 0; left: 0; right: 0; left: 0; bottom: 28px; overflow: auto;">
      <table class="b-table" :style="{ minWidth: calcMinWidth }" @mouseup.stop="onSeparatorMouseup()"
        @mousemove.stop="onSeparatorMousemove($event)">


        <slot name="header">
          <thead class="b-table-header">
            <tr class="b-table-row">
              <template v-for="(field, i) in model">
                <template v-if="field.hidden" />
                <template v-else>
                  <a-table-b-col :key="i" :sort="sort" :sortable="field.sortable" :width="filedsWidth[i]"
                    @sort="onClickHeader($event)" :field="field.name ? field.name : ''">
                    <div v-if="field.name == 'checked'" style="margin-top: -10px; margin-bottom: -22px">
                      <v-checkbox dense v-model="checkAll" />
                    </div>
                    <div v-else>
                      {{ field.title }}
                    </div>
                    <div class="b-table-separator" @mousedown.stop="onSeparatorMousedown($event, i)"></div>
                  </a-table-b-col>
                </template>
              </template>
            </tr>
            <tr v-if="searchable" class="b-table-row" :class="{ searchable: searchable }">
              <template v-for="(field, i) in model">
                <template v-if="field.hidden" />
                <template v-else>
                  <a-table-b-search :key="i" :model="field" :width="filedsWidth[i]" :values="filters"
                    @input="onSelectInput($event)">
                    <div class="b-table-separator" @mousedown.stop="onSeparatorMousedown($event, i)"></div>
                  </a-table-b-search>
                </template>
              </template>
            </tr>
          </thead>
        </slot>
        <slot>
          <tbody ref="body" class="b-table-body" :style="getStyleBody" :class="{ searchable: searchable }">
            <tr v-for="(row, key) in data" :key="key" class="b-table-row b-table-row-body"
              :class="{ selected: selected.value == row.id }">
              <template v-for="(field, i) in model">
                <template v-if="field.hidden" />
                <template v-else>
                  <td class="b-table-col" :key="i" :style="{ width: filedsWidth[i] + 'px' }"
                    @mousedown.stop="$emit('click', { row: row, field: field })">
                    <div class="b-table-col-inner" :style="{ width: filedsWidth[i] + 'px' }">
                      <slot :name="'item.' + field.name" :field="field" :item="row[field.name]" :row="row">
                        <component v-if="field.view && componentExist(field.view)" :is="getComponentName(field.view)"
                          :model="field" :value="row[field.name]" :values="row" />
                        <component
                          v-else-if="editable == true && field.canedit && field.type && componentExist(field.type, true)"
                          :is="getComponentName(field.type, true)" :model="field" :value="row[field.name]" :values="row"
                          @input="row[field.name] = $event; $emit('update-data', data)" />
                        <component v-else-if="field.type && componentExist(field.type)"
                          :is="getComponentName(field.type)" :model="field" :value="row[field.name]" :values="row" />
                        <div v-else-if="field.type == 'checkbox'">
                          <slot name="item.checkbox" v-bind:item="row">
                            <v-checkbox :value="row.checked" @click="clickCheckbox($event, row, key)" />
                          </slot>
                        </div>
                        <div v-else-if="field.type == 'folder'">
                          <slot name="item.folder" v-bind:item="row">
                            <v-icon v-if="row.isparent">far fa-folder</v-icon>
                            <v-icon v-else>far fa-file</v-icon>
                          </slot>
                        </div>
                        <div v-else-if="field.name == 'actions'">
                          <slot name="item.actions" v-bind:item="row" />
                        </div>
                        <div v-else>
                          {{ row[field.name] }}
                        </div>
                      </slot>
                      <div class="b-table-separator" @mousedown.stop="onSeparatorMousedown($event, i)"></div>
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </slot>
      </table>
    </div>
    <slot name="footer">
      <div v-if="footer" class="b-table-footer">
        <div class="b-table-row">
          <a-table-b-col v-for="(el, key) in footer" :key="key" :width="el.width" v-html="el.value"></a-table-b-col>
        </div>
      </div>
    </slot>
  </div>
</template>


<script>
import { components } from "../../../mixings";

let minWidth = 40;

export default {
  mixins: [components],
  props: {
    data: Array,
    model: [Object, Array],
    loading: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Object,
      default: {},
    },
    pager: {
      type: Object,
      default: {},
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      },
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Object,
      default: () => { },
    },
    selected: {
      type: Object,
      default: () => {
        return { text: "", value: 0 };
      },
    },
    checkedAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lastCheckClick: null,
      tableHeight: 0,
      filedsWidth: [],
      mousedown: { pressBtn: false, rowId: null, pos: null, currPos: null },
    };
  },
  watch: {
    model() {
      this.setWidth();
    },
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, true);
    window.addEventListener("resize", this.onResize, true);
    this.setWidth();
    this.onResize();
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, true);
    window.removeEventListener("resize", this.onResize, true);
  },
  computed: {
    checkAll: {
      get() {
        return this.checkedAll;
      },
      set(v) {
        this.$emit("checkedAll", v);
      }
    },
    getStyleBody() {
      let res = this.style_body;
      if (this.footer) {
        res.bottom = "30px";
      }
      return res;
    },
    calcMinWidth() {
      let w = 0;
      let add = 0;
      for (let el of this.filedsWidth) {
        w = w + parseFloat(el);
        add = add + 4;
      }
      return add + w + "px";
    },
    headers() {
      let res = [];
      for (let i = 0; i < this.model.length; i++) {
        res.push({ text: this.model[i].title, value: this.model[i].name });
      }
      res.push({ text: "Actions", value: "actions", sortable: false });
      return res;
    },
  },
  methods: {
    clickCheckbox(event, row, key) {
      if (event.shiftKey) {
        let start = this.lastCheckClick;
        let end = key;
        let dif = end - start;
        if (dif < 0) {
          dif = -dif;
          end = start;
          start = key;
        }
        for (let i = 0; i <= dif; i++) {
          let p = start + i;
          let a = this.data[p];
          a.checked = true;
          this.$set(this.data, p, a);
        }
      } else {
        this.lastCheckClick = key;
        row.checked = !row.checked;
      }
    },
    onClickHeader(field) {
      if (field && field != "checked") {
        this.$emit("sort", field);
      }
    },
    onSelectInput(e) {
      Object.assign(this.filters, e);
      this.$emit("change-filter", this.filters);
    },
    getElArrayByValue(array, field, name) {
      return array.find((x) => x[field] == name);
    },
    getModelByName(name) {
      return this.getElArrayByValue(this.model, "name", name);
    },
    setWidth() {
      this.filedsWidth = [];
      // let hashModel = JSON.stringify(this.model).md5();
      for (let i = 0; i < this.model.length; i++) {
        let width;
        if (this.model[i].width) width = parseFloat(this.model[i].width);
        else width = 100;
        this.$set(this.filedsWidth, i, width);
      }
    },
    onSeparatorMousedown(e, id) {
      if (!this.mousedown.pressBtn) {
        this.mousedown.pressBtn = true;
        this.mousedown.rowId = id;
        this.mousedown.pos = e.clientX;
        this.mousedown.currPos = this.filedsWidth[id];
        document.body.style["user-select"] = "none";
        // if (window.localStorage.getItem("leftSizePozition")) {
        //   this.$root.leftSizePozition = parseInt(
        //     window.localStorage.getItem("leftSizePozition")
        //   );
        // }
      }
    },
    onSeparatorMouseup: function () {
      if (this.mousedown.pressBtn) {
        this.mousedown.pressBtn = false;
        this.mousedown.rowId = null;
        document.body.style["user-select"] = "auto";
      }
    },
    onSeparatorMousemove: function (e) {
      if (this.mousedown.pressBtn) {
        let pos = this.mousedown.pos - e.clientX;
        let wid = this.mousedown.currPos - pos;
        if (wid < minWidth) {
          wid = minWidth;
        }
        // this.filedsWidth[this.mousedown.rowId] = this.filedsWidth[this.mousedown.rowId] - pos
        this.$set(this.filedsWidth, this.mousedown.rowId, wid);
      }
    },
    onScroll(e) {
      let el = this.$refs.table;
      let offset = 200;
      if (e.target == el) {
        if (el.scrollHeight - el.offsetHeight <= el.scrollTop + offset) {
          this.$emit("scrollEnd", e);
        }
      }
    },
    onResize() {
      let height = window.innerHeight;
      let top = this.$refs.table.getBoundingClientRect().top;
      this.tableHeight = height - top - 100;
    },
  },
};
</script>

<style lang="scss">
@mixin table-style($color,
  $borderColor,
  $backgroundColor,
  $backgroundColorSecond,
  $backgroundColorHover) {
  .b-table-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    border: 1px solid $borderColor;
  }

  .b-table {
    position: absolute;
    border-collapse: collapse;
    table-layout: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    box-sizing: border-box;
    background-color: $backgroundColor;
  }

  .b-table-header {
    height: 110px;

    td {
      position: sticky;
      top: 0;
      z-index: 1;
      background: $backgroundColor;
    }

    .searchable {
      td {
        box-shadow: inset 0 1px 0 $borderColor, inset 0 -1px 0 $borderColor;
        position: sticky;
        top: 80px;
      }
    }

    // border-top: 1px solid $borderColor;
    // border-bottom: 1px solid $borderColor;
    .b-table-row {
      min-height: 20px;
    }
  }

  .b-table-row {
    // display: flex;
    // flex-flow: row wrap;
    // min-height: 40px;
    border-right: 1px solid $borderColor;

    &.searchable {
      // border-top: 1px solid $borderColor;
      height: 30px;
    }
  }

  .b-table-body {
    // position: absolute;
    overflow-x: hidden;
    left: 0;
    right: 0;
    bottom: 30px;
    top: 28px !important;

    &.searchable {
      top: 58px !important;
    }

    .b-table-col {
      cursor: pointer;
    }

    .b-table-row.b-table-row-body {
      border-bottom: 1px solid $borderColor;
      background-color: $backgroundColor;

      &:hover {
        background: $backgroundColorHover;
      }
    }

    .b-table-row.b-table-row-body:nth-child(odd) {
      background-color: $backgroundColorSecond;

      &:hover {
        background-color: $backgroundColorHover;
      }
    }
  }

  .b-table-background {
    height: 100% !important;
    //   background: #fff !important;
  }

  .b-table-col {
    padding: 4px 6px;
    justify-content: center;
    // display: flex;
    // flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    border-left: 1px solid $borderColor;
    color: $color;

    &.sortable {
      flex-flow: row nowrap;
      justify-content: space-between;
      cursor: pointer;
    }

    .b-table-col-inner {
      overflow: hidden;
    }
  }

  .b-table-separator {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    right: 0;
    z-index: 1;
    margin: 0;
    border: none;
    cursor: col-resize;
  }

  .b-table-footer {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    border-top: 1px solid #d9d9d9;
    background: #eee;
    font-weight: bold;
  }
}

.theme--dark {
  $color: #bdbdbd;
  $borderColor: #808080;
  $backgroundColor: #161616;
  $backgroundColorSecond: lighten($backgroundColor, 3%);
  $backgroundColorHover: lighten($backgroundColor, 10%);
  @include table-style($color,
    $borderColor,
    $backgroundColor,
    $backgroundColorSecond,
    $backgroundColorHover );
}

.theme--light {
  $color: #1a1a1a;
  $borderColor: #a3a3a3;
  $backgroundColor: #ffffff;
  $backgroundColorSecond: darken($backgroundColor, 5%);
  $backgroundColorHover: darken($backgroundColor, 10%);
  @include table-style($color,
    $borderColor,
    $backgroundColor,
    $backgroundColorSecond,
    $backgroundColorHover );
}
</style>
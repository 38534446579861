<template>
  <div>{{value}} ({{values.count_products}})</div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
};
</script>
<template>
    <div>
        <span v-for="el, i in value" :key="i">
            <img class="mr-1" :src="$root.config.imageDownload + el + '?width=50&height=50'" height="50px" />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: Array,
    }
}
</script>
<template>
    <v-treeview open-on-click hoverable activatable :items="items" @update:active="onUpdate($event)">
    </v-treeview>
</template>

<script>
export default {
    props: {
        api: String,
        params: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            items: [],
            data: []
        }
    },
    watch: {
        api: {
            handler(v) {
                this.fitchData();
            },
            immediate: true,
        }
    },
    methods: {
        async fitchData() {
            const response = await this.$axios.get(this.api, { params: this.params });
            this.data = response.data.data;
            this.items = [];
            this.items = this.genData(0, this.data);
        },
        genData(parent_id, data) {
            let d = [];
            for (const el of data) {
                if (el.parent_id == parent_id) {
                    let a = {
                        id: el.id,
                        name: el.name
                    }
                    if (el.isparent) {
                        a.children = this.genData(el.id, data);
                    }
                    d.push(a);
                }
            }
            return d;
        },
        onUpdate(event) {
            let id = event[0];
            let el = this.data.find((el) => el.id == id);
            this.$emit('input', el)
        }
    }
}
</script>